export default {
    exclusionDeleteQuestion: "Vuoi cancellare questa esclusione?",
    deleteOrderQuestion: "Vuoi cancellare questo ordine?",
    sendOrderToCentralQuestion: "Vuole inviare questo ordine alla sede centrale?",
    placeOrderQuestion: "Vuoi fare questo ordine?",
    userActivateQuestion: "Sei sicuro di attivare questo utente?",
    videoUpdateQuestion: "Sei sicuro di voler aggiornare il video?",
    productCategoryUpdateQuestion: "Sei sicuro di voler aggiornare la famiglia di prodotti?",
    promoUpdateQuestion: "Sei sicuro di voler aggiornare questa promozione?",
    passwordUpdateQuestion: "Sei sicuro di voler cambiare la password?",
    bannerCreateQuestion: "Sei sicuro di voler creare il banner?",
    createClientQuestion: "Sei sicuro di aver creato il cliente?",
    userCreateQuestion: "Sei sicuro di voler creare l'utente?",
    videoCreateQuestion: "Sei sicuro di aver creato il video?",
    promoCreateQuestion: "Sei sicuro di voler creare la promozione?",
    userDeactivateQuestion: "Sei sicuro di voler disattivare questo utente?",
    promoDeleteQuestion: "Sei sicuro di voler cancellare questa promozione?",
    productCategoryDeleteQuestion: "Sei sicuro di voler eliminare la categoria di prodotti?",
    sendOrderQuestion: "Sei sicuro di voler inviare l'ordine?",
    notificationCreateQuestion: "Sei sicuro di voler creare una notifica?",
    loginIntoYourAccount: "Accedi al tuo account",
    access: "Accesso",
    actions: "Azioni",
    accept: "Accettare",
    enable: "Attivare",
    active: "Attivo",
    add: "Aggiungi",
    settings: "Impostazioni",
    addNewBarcode: "Aggiungere un nuovo codice a barre",
    addProduct: "Aggiungere prodotti",
    lastname: "Cognome",
    restrictedAreas: "Aree riservate",
    dragDocument: "Trascina il documento qui",
    dragImage: "Trascina l'immagine qui",
    subject: "Oggetto",
    warning: "Avviso",
    banners: "Banner",
    welcome: "Benvenuti a",
    draft: "Bozza",
    search: "Cerca",
    changePassword: "Cambiare la password",
    fieldRequired: "Campo obbligatorio",
    quit: "Cancella",
    quantity: "Quantità",
    loading: "Caricamento...",
    catalog: "Catalogo",
    categories: "Categoria",
    productCategory: "Categoria di prodotto",
    productCategories: "Categorie di prodotti",
    category: "Categorie",
    close: "Chiudere",
    cif: "NUMERO IVA",
    client: "Cliente",
    clients: "Clienti",
    clientsDeactivated: "Clienti disattivati",
    cloneOrder: "Ordine del clone",
    code: "Codice",
    barcodeRequired: "Codice a barre richiesto",
    postalCode: "Codice postale",
    barcodes: "Codici a barre",
    commercials: "Commerciale",
    deactivatedCommercials: "Spot pubblicitari disattivati",
    content: "Contenuto",
    password: "Password",
    currentPassword: "Password attuale",
    createdBy: "Creato da",
    create: "Creare",
    createExclusion: "Creare l'esclusione",
    newOrder: "Creare un nuovo ordine",
    contactInformation: "Dettagli del contatto",
    addProductToContinue: "devi aggiungere almeno un prodotto per continuare",
    disable: "Disattivare",
    description: "Descrizione",
    featured: "In primo piano",
    receiver: "Destinatari",
    deliveryAddress: "Indirizzo di consegna",
    shippingAddress: "Indirizzo di spedizione",
    distributors: "Distributori",
    document: "Documento",
    documentRequired: "Documento richiesto",
    documents: "Documenti",
    edit: "Edizione",
    editAnOrder: "Modificare un ordine",
    fieldMax256Characters: "Il campo deve essere al massimo di 256 caratteri",
    fieldMin2Characters: "Il campo deve essere di almeno 2 caratteri",
    fieldMin4Characters: "Il campo deve essere lungo almeno 4 caratteri",
    nameMin2Characters: "Il nome deve essere di almeno 2 caratteri",
    delete: "Cancellare",
    email: "Email",
    emailNoValid: "Email non valida",
    emailRequired: "Richiesto l'invio di un'e-mail",
    emails: "Email",
    start: "Iniziare",
    companies: "Aziende",
    onHold: "In attesa",
    sent: "Inviato a",
    sentBy: "Inviato da",
    send: "Invia",
    sendToCentral: "Inviare alla sede centrale",
    typeToSearch: "Digitare per cercare...",
    status: "Stato",
    exclusions: "Esclusioni",
    invoice: "Fattura",
    date: "Data",
    commercialFile: "File commerciale",
    companyFile: "Profilo dell'azienda",
    personalFile: "Profilo personale",
    filters: "Filtri",
    finalized: "Completato",
    save: "Salva",
    saveOrder: "Salvare l'ordine",
    image: "Immagine",
    imageRequired: "Immagine richiesta",
    inactive: "Inattivo",
    home: "Casa",
    referenceRequired: "Il riferimento è richiesto",
    matchPasswords: "Le password devono corrispondere",
    brand: "Marchio",
    brands: "Marchi",
    message: "Messaggio",
    myCompany: "La mia azienda",
    myClients: "I miei clienti",
    myCommercials: "I miei spot",
    myBills: "Le mie fatture",
    myOrders: "I miei ordini",
    showOnWeb: "Mostra sul web",
    nif: "NUMERO IVA",
    none: "Nessuno",
    createOneClientQuestion: "Nessun cliente creato, vuoi crearne uno?",
    noResults: "Nessun risultato trovato",
    name: "Nome",
    commercialName: "Nome commerciale",
    contactName: "Nome del contatto",
    pathName: "Nome del percorso",
    fiscalName: "Nome fiscale",
    nameRequired: "Nome richiesto",
    notifications: "Notifiche",
    newCategory: "Nuova categoria",
    newPassword: "Nuova password",
    password4CharactersRequired: "Nuova password di 4 caratteri minimo",
    newPasswordRequired: "Nuova password richiesta",
    newExclusion: "Nuova esclusione",
    newNotification: "Nuova notifica",
    newPromo: "Nuova promozione",
    newBanner: "Nuovo banner",
    newClient: "Nuovo cliente",
    newBarcode: "Nuovo codice a barre",
    newUser: "Nuovo utente",
    newAdminUser: "Nuovo utente admin",
    newCommercial: "Nuovo utente commerciale",
    newVideo: "Nuovo video",
    number: "Numero",
    observations: "Osservazioni",
    screenHome: "Schermata iniziale",
    screens: "Schermi",
    help: "Per aiuto con questo pannello, contattateci a",
    orders: "Ordine",
    profile: "Profilo",
    population: "Città",
    pleaseFillField: "Si prega di compilare questo campo",
    price: "Prezzo",
    pvpPrices: "PVP",
    product: "Prodotto",
    addedProduct: "Prodotto aggiunto",
    products: "Prodotti",
    specialSale: "Promozione",
    specialSales: "Promozioni speciali",
    province: "Provincia",
    pvp: "RRP",
    placeAnOrder: "Effettuare un ordine",
    reference: "Riferimento",
    repeatPasswordRequired: "Ripetere la password richiesta",
    repeatPassword: "Ripetere la password",
    toRestrict: "Limitare",
    restrictAreas: "Limitare le aree",
    path: "Percorso",
    logout: "Uscita",
    security: "Sicurezza",
    synchronization: "Sincronizzazione",
    syncCompanies: "Sincronizzare le aziende",
    syncProducts: "Sincronizzare i prodotti",
    subCategory: "Sottocategoria",
    rate: "Tariffa",
    rates: "Tariffe",
    phone: "Telefono",
    phones: "Telefoni",
    end: "Finisce",
    type: "Tipo",
    ivaType: "Aliquota IVA",
    typeRequired: "Tipo richiesto",
    total: "Totale",
    url: "Url",
    urlNoValid: "Url non valido",
    urlRequired: "Url richiesto",
    users: "Utenti",
    sales: "Vendite",
    viewInactive: "Vedi inattivo",
    videos: "Video",
    views: "Visualizzazioni",
    back: "Torna a",
    zone: "Zona",
    generalDetails: "",
}