import {Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store/index";

@Module({dynamic: true, store, name: "DrawerModule"})
export default class DrawerModule extends VuexModule {

    mini: boolean = false


    @Mutation
    closeMiniDrawer() {
        this.mini = !this.mini
    }

    @Mutation
    setMiniDrawerEnabled(enabled: boolean) {
        this.mini = enabled
    }

}