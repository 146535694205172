import ConstantTool from "@/services/tool/ConstantTool";
import * as VueGoogleMaps from 'vue2-google-maps'
import {Vue} from "vue-property-decorator";

export default class MapsConfig {

    // npm install vue2-google-maps

    static init(vue: Vue) {
        if (ConstantTool.GOOGLE_MAPS) {
            Vue.use(VueGoogleMaps, {
                load: {
                    key: 'AIzaSyAiXZRY4bHh5UF3fW81O6MHc5hUvHHyTOA',
                    // libraries: 'places', // This is required if you use the Autocomplete plugin
                    // OR: libraries: 'places,drawing'
                    // OR: libraries: 'places,drawing,visualization'
                    // (as you require)

                    //// If you want to set the version, you can do so:
                    // v: '3.26',
                },

                //// If you intend to programmatically custom event listener code
                //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
                //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
                //// you might need to turn this on.
                // autobindAllEvents: false,

                //// If you want to manually install components, e.g.
                //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
                //// Vue.component('GmapMarker', GmapMarker)
                //// then set installComponents to 'false'.
                //// If you want to automatically install all the components this property must be set to 'true':
                installComponents: true
            })
        }
    }

    /*
    <GmapMap
            :center="{lat:38, lng:-1}"
            :zoom="7"
            style="width: 100%; height: 300px"
            map-type-id="terrain"
    >
        <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center=m.position"
        />
    </GmapMap>
     */

}
