import {JsonObject, JsonProperty} from "json2typescript";
import Auditing from "@/models/Auditing";
import User from "@/models/User";

@JsonObject("Notification")
export default class Notification extends Auditing {

    @JsonProperty("id", Number, true)
    id?: number = undefined

    @JsonProperty("receivers", [User], true)
    receivers?: User[] = undefined

    @JsonProperty("subject", String, true)
    subject?: string = undefined

    @JsonProperty("message", String, true)
    message?: string = undefined

    @JsonProperty("targetCompanyId", Number, true)
    targetCompanyId?: number = undefined

}