import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("Zone")
export default class Zone {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("connectorId", String, true)
    connectorId?: string = undefined
    @JsonProperty("name", String, true)
    name?: string = undefined

}