export default {
    exclusionDeleteQuestion: "Voulez-vous supprimer cette exclusion ?",
    deleteOrderQuestion: "Voulez-vous supprimer cette commande ?",
    sendOrderToCentralQuestion: "Voulez-vous envoyer cette commande au siège social ?",
    placeOrderQuestion: "Voulez-vous passer cette commande ?",
    userActivateQuestion: "Etes-vous sûr d'activer cet utilisateur ?",
    videoUpdateQuestion: "Vous êtes sûr de vouloir mettre à jour la vidéo ?",
    productCategoryUpdateQuestion: "Êtes-vous sûr de vouloir mettre à jour la famille de produits ?",
    promoUpdateQuestion: "Êtes-vous sûr de vouloir mettre à jour cette promotion ?",
    passwordUpdateQuestion: "Êtes-vous sûr de vouloir changer le mot de passe ?",
    bannerCreateQuestion: "Êtes-vous sûr de vouloir créer la bannière ?",
    createClientQuestion: "Êtes-vous sûr d'avoir créé le client ?",
    userCreateQuestion: "Êtes-vous sûr d'avoir créé l'utilisateur ?",
    videoCreateQuestion: "Êtes-vous sûr d'avoir créé la vidéo ?",
    promoCreateQuestion: "Êtes-vous sûr de vouloir créer la promotion ?",
    userDeactivateQuestion: "Etes-vous sûr de vouloir désactiver cet utilisateur ?",
    promoDeleteQuestion: "Êtes-vous sûr de vouloir supprimer cette promotion ?",
    productCategoryDeleteQuestion: "Êtes-vous sûr de vouloir supprimer la catégorie de produits ?",
    sendOrderQuestion: "Êtes-vous sûr de vouloir envoyer la commande ?",
    notificationCreateQuestion: "Êtes-vous sûr de vouloir créer une notification ?",
    loginIntoYourAccount: "Connectez-vous à votre compte",
    access: "Connexion",
    actions: "Actions",
    accept: "Accepter",
    enable: "Activez",
    active: "Actif",
    add: "Ajouter",
    settings: "Paramètres",
    addNewBarcode: "Ajouter un nouveau code-barres",
    addProduct: "Ajouter des produits",
    lastname: "Nom de famille",
    restrictedAreas: "Zones à accès restreint",
    dragDocument: "Faites glisser le document ici",
    dragImage: "Faites glisser l'image ici",
    subject: "Sujet",
    warning: "Avis",
    banners: "Bannières",
    welcome: "Bienvenue à",
    draft: "Draft",
    search: "Recherche",
    changePassword: "Modifier le mot de passe",
    fieldRequired: "Champ obligatoire",
    quit: "Annuler",
    quantity: "Quantité",
    loading: "Chargement...",
    catalog: "Catalogue",
    categories: "Catégorie",
    productCategory: "Catégorie de produit",
    productCategories: "Catégories de produits",
    category: "Catégories",
    close: "Fermer",
    cif: "Número TVA",
    client: "Client",
    clients: "Clients",
    clientsDeactivated: "Clients désactivés",
    cloneOrder: "Ordre de clonage",
    code: "Code",
    barcodeRequired: "Code-barres requis",
    postalCode: "Zip/Code postal",
    barcodes: "Codes-barres",
    commercials: "Commercial",
    deactivatedCommercials: "Commerces désactivés",
    content: "Contenu",
    password: "Mot de passe",
    currentPassword: "Mot de passe actuel",
    createdBy: "Créé par",
    create: "Créer",
    createExclusion: "Créer une exclusion",
    newOrder: "Créer une nouvelle commande",
    contactInformation: "Coordonnées de contact",
    addProductToContinue: "vous devez ajouter au moins un produit pour continuer",
    disable: "Désactiver",
    description: "Description",
    featured: "En vedette :",
    receiver: "Adresses",
    deliveryAddress: "Adresse de livraison",
    shippingAddress: "Adresse de livraison",
    distributors: "Distributeurs",
    document: "Document",
    documentRequired: "Document requis",
    documents: "Documents",
    edit: "Edition",
    editAnOrder: "Modifier une commande",
    fieldMax256Characters: "Le champ doit comporter un maximum de 256 caractères",
    fieldMin2Characters: "Le champ doit comporter un minimum de 2 caractères",
    fieldMin4Characters: "Le champ doit comporter au moins 4 caractères",
    nameMin2Characters: "Le nom doit comporter au moins 2 caractères",
    delete: "Supprimer",
    email: "Email",
    emailNoValid: "Adresse électronique non valide",
    emailRequired: "Courriel requis",
    emails: "Emails",
    start: "Début",
    companies: "Entreprises",
    onHold: "En attente",
    sent: "Envoyé à",
    sentBy: "Envoyé par",
    send: "Envoyer",
    sendToCentral: "Envoyer au siège social",
    typeToSearch: "Tapez pour rechercher...",
    status: "Statut",
    exclusions: "Exclusions",
    invoice: "Facture",
    date: "Date",
    commercialFile: "Onglet Vendeur",
    companyFile: "Profil de l'entreprise",
    personalFile: "Profil personnel",
    filters: "Filtres",
    finalized: "Complété",
    save: "Sauver",
    saveOrder: "Sauvegarder la commande",
    image: "Image",
    imageRequired: "Image requise",
    inactive: "Inactif",
    home: "Accueil",
    referenceRequired: "La référence est requise",
    matchPasswords: "Les mots de passe doivent correspondre",
    brand: "Marque",
    brands: "Marques",
    message: "Message",
    myCompany: "Mon entreprise",
    myClients: "Mes clients",
    myCommercials: "Mes publicités",
    myBills: "Mes factures",
    myOrders: "Mes ordres",
    showOnWeb: "Montrer sur le web",
    nif: "Número TVA",
    none: "Aucun",
    createOneClientQuestion: "Aucun client n'a été créé, souhaitez-vous en créer un ?",
    noResults: "Aucun résultat trouvé",
    name: "Prénom",
    commercialName: "Nom commercial",
    contactName: "Nom du contact",
    pathName: "Nom de la route",
    fiscalName: "Nom fiscal",
    nameRequired: "Nom requis",
    notifications: "Notifications",
    newCategory: "Nouvelle catégorie",
    newPassword: "Nouveau mot de passe",
    password4CharactersRequired: "Nouveau mot de passe de 4 caractères minimum",
    newPasswordRequired: "Nouveau mot de passe requis",
    newExclusion: "Nouvelle exclusion",
    newNotification: "Nouvelle notification",
    newPromo: "Nouvelle promotion",
    newBanner: "Nouvelle bannière",
    newClient: "Nouveau client",
    newBarcode: "Nouveau code-barres",
    newUser: "Nouvel utilisateur",
    newAdminUser: "Nouvel utilisateur administrateur",
    newCommercial: "Nouvel utilisateur commercial",
    newVideo: "Nouvelle vidéo",
    number: "Numéro",
    observations: "Remarques",
    screenHome: "Écran d'accueil",
    screens: "Écrans",
    help: "Pour obtenir de l'aide sur ce panneau, veuillez nous contacter à ",
    orders: "Commandes",
    profile: "Profil",
    population: "Ville",
    pleaseFillField: "Veuillez remplir ce champ",
    price: "Prix",
    pvpPrices: "PVP",
    product: "Produit",
    addedProduct: "Produit ajouté",
    products: "Produits",
    specialSale: "Promotion",
    specialSales: "Promotions spéciales",
    province: "Province",
    pvp: "RRP",
    placeAnOrder: "Passer une commande",
    reference: "Référence",
    repeatPasswordRequired: "Répétition du mot de passe requise",
    repeatPassword: "Répéter le mot de passe",
    toRestrict: "Restreindre",
    restrictAreas: "Limiter les zones",
    path: "Route",
    logout: "Sortie",
    security: "Sécurité",
    synchronization: "Synchronisation",
    syncCompanies: "Synchroniser les entreprises",
    syncProducts: "Synchroniser les produits",
    subCategory: "Sous-catégorie",
    rate: "Tarif",
    rates: "Tarifs",
    phone: "Téléphone",
    phones: "Téléphones",
    end: "Fin",
    type: "Type",
    ivaType: "Taux de TVA",
    typeRequired: "Type requis",
    total: "Total",
    url: "Url",
    urlNoValid: "Url non valide",
    urlRequired: "Url requis",
    users: "Utilisateurs",
    sales: "Ventes",
    viewInactive: "Vue inactive",
    videos: "Vidéos",
    views: "Vues",
    back: "Retour à",
    zone: "Zone",
    generalDetails: "",
}