import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import DrawerItem from "@/models/vue/DrawerItem";
import RouteGroups from "@/services/tool/RouteGroups";
let lang = getModule(LangModule).lang

export default {

    superAdminList: [
        ...RouteGroups.homeRoutes,
        ...RouteGroups.catalogueRoutes,
        ...RouteGroups.salesRoutes,
        ...RouteGroups.marketingRoutes,
        ...RouteGroups.settingsRoutes,
    ],

    adminList: [
        ...RouteGroups.homeRoutes,
        ...RouteGroups.catalogueRoutes,
        ...RouteGroups.salesRoutes,
        ...RouteGroups.marketingRoutes,
        ...RouteGroups.settingsRoutes,
    ],

    distributorList: [
        ...RouteGroups.homeRoutes,
        ...RouteGroups.orderSalesRoutes,
        ...RouteGroups.headquarterRoutes
    ],

    commercialList: [
        ...RouteGroups.homeRoutes,
        ...RouteGroups.orderSalesRoutes,
    ],

    marketingManagerList: [
        ...RouteGroups.homeRoutes,
        ...RouteGroups.catalogueRoutes,
        ...RouteGroups.marketingRoutes
    ],

    managerList: [
        ...RouteGroups.homeRoutes,
        ...RouteGroups.catalogueRoutes,
        ...RouteGroups.salesRoutes,
    ],

}