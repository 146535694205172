export default {
    loginIntoYourAccount: "Accede a tu cuenta",
    email: "Email",
    emails: "Emails",
    password: "Contraseña",
    access: "Acceder",
    welcome: "Bienvenidos a ",
    help: "Para obtener ayuda sobre este panel, por favor póngase en contacto con nosotros a través de",
    home: "Inicio",
    catalog: "Catálogo",
    products: "Productos",
    productsCategory: "Categoria de productos",
    brands: "Marcas",
    sales: "Ventas",
    companies: "Empresas",
    myCompany: "Mi Empresa",
    orders: "Pedidos",
    specialSales: "Promociones Especiales",
    specialSale: "Promocion",
    videos: "Videos",
    banners: "Banners",
    notifications: "Notificaciones",
    settings: "Ajustes",
    synchronization: "Sincronizacion",
    users: "Usuarios",
    exclusions: "Exclusiones",
    restrictAreas: "Restringir Areas",
    clients: "Clientes",
    companyFile: "Ficha de empresa",
    distributors: "Distribuidores",
    address: "Direccion",
    addresses: "Direcciones",
    province: "Provincia",
    rate: "Tarifa",
    rates: "Tarifas",
    commercials: "Comerciales",
    commercialsDeactivated: "Comerciales desactivados",
    myCommercials: "Mis comerciales",
    newCommercial: "Nuevo usuario comercial",
    myClients: "Mis clientes",
    newClient: "Nuevo cliente",
    clientsDeactivated: "Clientes desactivados",
    myBills: "Mis facturas",
    filters: "Filtros",
    newOrder: "Crear nuevo pedido",
    send: "Enviar",
    cloneOrder: "Clonar pedido",
    back: "Volver",
    quantity: "Cantidad",
    product: "Producto",
    orderDetails: "Detalles del pedido",
    deliveryAddress: "dirección de entrega",
    newPromo: "Nueva Promocion",
    newVideo: "Nuevo Video",
    views: "Visualizaciones",
    newBanner: "Nuevo Banner",
    newNotification: "Nueva Notificacion",
    synchronizeCompanies: "Sincronizar Empresas",
    synchronizeProducts: "Sincronizar Productos",
    start: "Empieza",
    end: "Termina",
    viewInactive: "Ver Inactivos",
    newExclusion: "Nueva Exclusion",
    profile: "Perfil",
    personalFile: "Ficha Personal",
    myOrders: "Mis pedidos",
    security: "Seguridad",
    changePassword: "Cambiar Contraseña",
    contactInformation: "Datos de Contacto",
    accessInformation: "Datos de acceso",
    search: "Buscar",
    searchByCategory: "Buscar por categoría",
    image: "Imagen",
    username: "Nombre de usuario",
    name: "Nombre",
    lastname: "Apellido",
    code: "Codigo",
    brand: "Marca",
    reference: "Referencia",
    category: "Categoria",
    subCategory: "Subcategoria",
    fiscalName: "Nombre Fiscal",
    cif: "CIF",
    nif: "NIF",
    date: "Fecha",
    createdBy: "Creado Por",
    document: "Documento",
    documents: "Documentos",
    update: "Actualizar",
    status: "Estado",
    noResults: "No hay resultados",
    url: "Url",
    hour: "Hora",
    type: "Tipo",
    subject: "Asunto",
    receiver: "Destinarios",
    receivers: "Destinarios",
    commercialName: "Nombre Commercial",
    zone: "Zona",
    productCategory: "Categoria de Producto",
    actions: "Acciones",
    pathName: "Nombre de la ruta",
    path: "Ruta",
    phone: "Telefono",
    phones: "Telefonos",
    logout: "Salir",
    quit: "Cancelar",
    none: "Ninguna",
    loading: "Cargando...",
    edit: "Edición",
    barcodes: "Códigos de barras",
    number: "Numero",
    price: "Precio",
    content: "Contenido",
    description: "Descripción",
    observations: "Observaciones",
    showOnWeb: "Mostrar en web",
    showingOnWeb: "Mostrando en la web",
    notShowingOnWeb: "No mostrandose en la web",
    featured: "Novedades",
    ivaType: "Tipo IVA",
    dragImage: "Arrastre la imagen aquí",
    dragDocument: "Arrastre el documento aquí",
    newBarcode: "Nuevo codigo de barras",
    addNewBarcode: "Añade el nuevo codigo de barras",
    save: "Guardar",
    categories: "Categorias",
    barcodeRequired: "Código de barras requerido",
    productCategories: "Categorias de Productos",
    newCategory: "Nueva Categoria",
    create: "Crear",
    referenceRequired: "La referencia es requerida",
    delete: "Eliminar",
    deleteDocument: "Eliminar Documento",
    warning: "Aviso",
    productCategoryDeleteQuestion: "¿Está seguro de eliminar la categoria de productos?",
    productCategoryUpdateQuestion: "¿Está seguro de actualizar la familia de productos?",
    accept: "Aceptar",
    typeToSearch: "Escriba para buscar...",
    sent: "Enviado",
    finalized: "Finalizado",
    draft: "Borrador",
    onHold: "En Espera",
    documentRequired: "Documento Requerido",
    promoCreateQuestion: "¿Está seguro de crear la promoción?",
    promoUpdateQuestion: "¿Está seguro de actualizar la promoción?",
    promoDeleteQuestion: "¿Está seguro de eliminar esta promoción?",
    urlRequired: "Url requerida",
    urlNoValid: "Url no es válida",
    videoCreateQuestion: "¿Está seguro de crear el video?",
    videoUpdateQuestion: "¿Está seguro de actualizar el video?",
    nameRequired: "Nombre requerido",
    typeRequired: "Tipo requerido",
    imageRequired: "Imagen requerida",
    bannerCreateQuestion: "¿Está seguro de crear el banner?",
    bannerUpdateNotification: "¿Está seguro de actualizar el banner?",
    notificationCreateQuestion: "¿Está seguro que desea crear una notificacion?",
    message: "Mensaje",
    syncCompanies: "Sincronizar empresas",
    syncProducts: "Sincronizar productos",
    newUser: "Nuevo Usuario",
    newAdminUser: "Nuevo usuario administrador",
    emailRequired: "Email requerido",
    emailNoValid: "Email no valido",
    nameMin2Characters: "El nombre deber ser de minimo 2 caracteres",
    userCreateQuestion: "¿Está seguro de crear el usuario?",
    deactivate: "Desactivar",
    activate: "Activar",
    restrictedAreas: "Areas Restringidas",
    passwordUpdateQuestion: "¿Está seguro de cambiar la contraseña?",
    userDeactivateQuestion: "¿Está seguro de desactivar este usuario?",
    userActivateQuestion: "¿Está seguro de activar este usuario?",
    createExclusion: "Crear Exclusión",
    editExclusions: "Editar Exclusión",
    fieldRequired: "Campo requerido",
    exclusionDeleteQuestion: "¿Desea eliminar esta exclusion?",
    screens: "Pantallas",
    screenHome: "Pantalla de inicio",
    toRestrict: "Restringir",
    active: "Activo",
    inactive: "Inactivo",
    deactivatedCommercials: "Comerciales Desactivados",
    commercialFile: "Ficha de Comercial",
    disabledClients: "Clientes desactivados",
    contactName: "Nombre de contacto",
    pleaseFillField: "Por favor, rellene este campo",
    fieldMin4Characters: "El campo debe ser de mínimo 4 caracteres",
    fieldMin2Characters: "El campo debe ser de mínimo 2 caracteres",
    fieldMax256Characters: "El campo debe ser de maximo 256 caracteres",
    createClientQuestion: "¿Está seguro de crear el cliente?",
    shippingAddress: "Dirección de envío",
    postalCode: "Codigo postal",
    invalidPostalCode: "Codigo postal invalido",
    population: "Población",
    deleteOrderQuestion: "¿Desea eliminar este pedido?",
    sendOrderQuestion: "¿Está seguro de enviar el pedido?",
    total: "Total",
    pvp: "PVP",
    sentBy: "Enviado Por",
    client: "Cliente",
    pvpPrices: "Precios PVP",
    sendToCentral: "Enviar a central",
    placeAnOrder: "Realizar pedido",
    budget: "Presupuesto",
    saveAnBudget: "Guardar presupuesto",
    placeAnBudget: "Realizar presupuesto",
    sendToClient: "Enviar a Cliente",
    addProduct: "Añadir productos",
    addDocument: "Añadir documento",
    ratePrice: "P. Tarifa",
    editAnOrder: "Editar un pedido",
    saveOrder: "Guardar pedido",
    add: "Agregar",
    placeOrderQuestion: "¿Desea realizar este pedido?",
    addProductToContinue: "Debe añadir por lo menos un producto para continuar",
    saveOrderQuestion: "¿Desea guardar este pedido?",
    sendOrderToCentralQuestion: "¿Desea enviar a central este pedido?",
    createOneClientQuestion: "No hay clientes creados, ¿Desea ir a crear uno?",
    addedProduct: "Producto añadido",
    close: "Cerrar",
    invoice: "Factura",
    waiting: "En Espera",
    currentPassword: "Contraseña actual",
    replacePassword: "Reemplazar mi contraseña",
    newPassword: "Nueva Contraseña",
    repeatPassword: "Repite Contraseña",
    matchPasswords: "Las contraseñas deben coincidir",
    repeatPasswordRequired: "Repite contraseña requerida",
    requiredPassword: "Contraseña requerida",
    newPasswordRequired: "Nueva contraseña requerida",
    password4CharactersRequired: "Nueva contraseña de 8 caracteres mímino",
    password8CharactersRequired: "Nueva contraseña de 8 caracteres mímino",
    notificationEmail: "Email de notificaciones",
    orderReferenceHeader: "Ref. Pedido",
    buyDateAtHeader: "Fecha de compra",
    searching: "Buscando...",
    filterByProduct: "Filtar por producto",
    filterByDate: "Filtrar por fecha",
    filterByStatus: "Filtrar por estado",
    filterByCompany: "Filtrar por empresa",
    filterByClient: "Filtrar por cliente",
    filterByCommercial: "Filtrar por comercial",
    filterByPrice: "Filtrar por precio",
    clearFilters: "Limpiar filtros",
    ordersTableTip: "Los pedidos conservan su posición en la lista aún despues de ser editados.",
    generalDetails: "Detalles generales",
    priceFrom: "Desde",
    priceTo: "Hasta",
    position: "Posición",
    imageNotAvaliable: "Imagen no disponible",
    newlyOrderSendWarning: "Parece que intentas enviar un pedido pedido recien creado directamente hacia la central, ¿Estas seguro de proceder con el envio?",
    clientFile: "Ficha del cliente",
    novelties: "Novedades",
    activity: "Actividad",
    exportToCsv: "Exportar a CSV",
    lastActivatedAt: "Activado por ultima vez el",
    bannerNotActivatedYet: "Parece que este banner no ha sido activado por primera vez aún.",
    productNotFeaturedYet: "Parece que este producto no se ha promocionado antes.",
    bannerImageRequiredActivation: "Los banners necesitan una imagen para ser activados.",
    loadingData: "Cargando datos...",
    dropYourImageHere: "Arrastra la imagen aquí",
    clear: "Limpiar",
    clearBannerConfirmationMessage: "¿Está seguro de limpiar el banner? Esto lo desactivará hasta que se suba una nueva imagen y se active manualmente de nuevo.",
    deleteBannerConfirmationMessage: "¿Está seguro de eliminar el banner? Esta acción es irreversible.",
    exportToPdf: "Exportar PDF",
    potentialClients: "Clientes potenciales",
    potentialClient: "Cliente potencial",
    editAnBudget: "Editar un presupuesto",
    alreadyAddedProductMessage: "Parece que este producto ya se ha añadido en el presupuesto con anterioridad, por lo que su cantidad será reemplazada.",
    removeProductFromBudgetConfirmation: "¿Desea remover el producto de su presupuesto?",
    removeProductFromOrderConfirmation: "¿Desea remover el producto de su pedido?",
    placeBudgetConfirmation: "¿Desea realizar este presupuesto?",
    saveBudgetConfirmation: "¿Desea guardar este presupuesto?",
    sendBudgetConfirmation: "¿Desea enviar este presupuesto?",
    usageLog: "Registro de uso",
    deleteClientConfirmation: "¿Está seguro de eliminar este cliente? Si este cliente fué usado en alguna otra instancia será desactivado, de lo contrario sera eliminado permanentemente.",
    deletePotentialClientConfirmation: "¿Está seguro de eliminar este cliente potencial? Si este cliente fué usado en alguna otra instancia será desactivado, de lo contrario sera eliminado permanentemente.",
    deactivateClientConfirmation: "¿Está seguro de desactivar este cliente?",
    deactivatePotentialClientConfirmation: "¿Está seguro de desactivar este cliente potencial?",
    activateClientConfirmation: "¿Está seguro de activar este cliente?",
    activateCommercialConfirmation: "¿Está seguro de activar este commercial?",
    deactivateCommercialConfirmation: "¿Está seguro de desactivar este commercial?",
    deleteUserConfirmation: "¿Está seguro de eliminar este usuario?",
    activateCRM: "Activar CRM",
    deactivateCRM: "Desactivar CRM",
    banner: "Banner",
    forgottenPasswordQuestion: "¿Has olvidado tu contraseña?",
    forgottenPasswordHint: "Gracias por pedir ayuda a Perfect Beauty, ingrese el correo correspondiente a su cuenta en el siguiente campo y en breve recibirá un correo con instrucciones para recuperar su contraseña.",
    budgets: "Presupuestos",
    createBudget: "Crear presupuesto",
    options: "Opciones",
    seeBudget: "Ver presupuesto",
    editBudget: "Editar Presupuesto",
    deleteBudget: "Eliminar presupuesto",
    budgetTableHint: "Los presupuestos conservan su posición en la lista aún despues de ser editados.",
    title: "Titulo",
    resend: "Reenviar",
    sendNotificationConfirmation: "¿Está seguro de enviar la notificación?",
    newPotentialClient: "Nuevo Cliente Potencial",
    myPotentialClients: "Mis clientes potenciales",
    createProductCategoryConfirmation: "¿Está seguro de crear una nueva categoría de productos?",
    selectCategories: "Seleccionar categorías",
    productFile: "Ficha de producto",
    languages: "Idiomas",
    noRates: "No hay tarifas",
    updateProductConfirmation: "¿Está seguro de actualizar el producto?",
    updateDocumentsConfirmation: "¿Está seguro de actualizar los documentos",
    deleteProductDocumentsConfirmation: "¿Está seguro de querer eliminar los documentos adjuntos al producto?",
    deleteBarcodeConfirmation: "¿Está seguro de eliminar este código de barras?",
    addBarcodeConfirmation: "¿Está seguro de añadir un código de barras?",
    addFeatureConfirmation: "¿Está seguro de añadir una característica?",
    deleteFeatureConfirmation: "¿Está seguro de eliminar esta característica?",
    mustChooseCategory: "Debes seleccionar una categoría.",
    addCategoryToProductConfirmation: "Esta categoría será añadida a este producto, ¿Esta seguro?",
    excludeCategoryFromProductConfirmation: "El producto será excluido de esta categoría, ¿Esta seguro?",
    userType: "Tipo de usuario",
    administrator: "Administrador",
    manager: "Gestor",
    marketingManager: "Gestor de Marketing",
    requiredField: "Campo requerido",
    deleteAddressConfirmation: "¿Está seguro de eliminar esta Dirección?",
    createAddressConfirmation: "¿Está seguro de crear esta Dirección?",
    updateAddressConfirmation: "¿Está seguro de actualizar esta Dirección?",
    updateUserConfirmation: "¿Está seguro de actualizar el usuario?",
    newAddress: "Nueva Dirección",
    createAddress: "Crear Dirección",
    operationConfirmation: "¿Está seguro que desea realizar esta operación?",
    file: "Archivo",
    fileRequired: "Archivo requerido",
    uploadDate: "Fecha de subida",
    uploadedBy: "Subido por",
    documentFileNotFound: "El archivo de este documento no existe",
    deleteDocumentConfirmation: "¿Está seguro de eliminar el documento?",
    createDocumentConfirmation: "¿Está seguro de añadir el documento?",

    openOrder: "Abrir pedido",
    nonexistentClient: "Cliente inexsistente",
    errorSearchingClient: "Error al buscar al cliente",
    unknowError: "Error desconocido",
    enableCommercials: "Activar comerciales",
    disableCommercials: "Desactivar comerciales",
    atLeastOneRequired: "Seleccione un item para continuar.",
    inProgress: "En Progreso",
    postponed: "Aplazado",
    creationDate: "Fecha de Creación",
    synchronize: "Sincronizar",
    enabled: "Activo",
    disabled: "Inactivo",
    blocked: "Bloqueado",
    xgestStatus: "Estado de Xgest",
    user: "Usuario",
    headquarter: "Sede",
    headquarters: "Sedes",
    createHeadquarter: "Crear sede",
    headquarterFile: "Ficha de sede",
    headquarterSuccessfullyCreated: "Sede creada exitosamente!",
    errorOccurred: "Ha ocurrido un error.",
}

