import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import '@/config/style.css'
// import es from 'vuetify/es5/locale/es' // LANGUAGE

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: { // regular #004445, #2c7873, #6fb98f, #ffd800
      light: {
        primary: '#1976D2',
        secondary: '#325288',
        accent: '#a49200',
        error: '#d9534f',
        info: '#3da9de',
        success: '#00b879',
        warning: '#f0ad4e',

        "pb-success": '#F5EB84',
        "pb-grey": '#DDDDDD',
        "pb-grey-darken-1": '#B4B4B5',
        "pb-grey-darken-2": '#8B8D8D',
        "pb-grey-darken-3": '#686867',
        "pb-grey-darken-4": '#545454',
        "pb-grey-lighten-1": '#EDEDED',
        "pb-grey-lighten-2": '#F8F8F8',
        "pb-barberia": '#75A9A6',
        "pb-estetica": '#D089AC',
        "pb-peluqueria": '#DCD17E',
      },
      dark: {
        primary: '#1976D2',
        secondary: '#454545',
        accent: '#a49200',
        error: '#d9534f',
        info: '#3da9de',
        success: '#00b879',
        warning: '#f0ad4e',

        "pb-success": '#F5EB84',
        "pb-grey": '#DDDDDD',
        "pb-grey-darken-1": '#B4B4B5',
        "pb-grey-darken-2": '#8B8D8D',
        "pb-grey-darken-3": '#686867',
        "pb-grey-darken-4": '#545454',
        "pb-grey-lighten-1": '#EDEDED',
        "pb-grey-lighten-2": '#F8F8F8',
        "pb-barberia": '#75A9A6',
        "pb-estetica": '#D089AC',
        "pb-peluqueria": '#DCD17E',
      },
    },
  },

  // LANGUAGE
  // lang: {
  //   locales: { es },
  //   current: 'es'
  // },

  icons: {
    iconfont: 'mdi', // materialdesignicons
  },
});
