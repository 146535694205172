import VImageInput from "vuetify-image-input";
import {Vue} from "vue-property-decorator";
import FileSelector from 'vue-file-selector';

export default class ImageInputConfig {

    // https://github.com/d2phap/vue-file-selector                  -
    // https://github.com/pqina/vue-filepond ->                     no me gusta
    // https://github.com/crcatala/vue-full-screen-file-drop ->     es fullscreen
    // https://github.com/alexsasharegan/vue-transmit ->            probada y no mola por la config gorda

    /*
    https://github.com/SeregPie/VuetifyImageInput - > drag and drop base64 without file :(
    <v-image-input v-model="imageData" :image-quality="0.85" clearable
                   imageMinScaling="contain"
                   hideActions
                   fullWidth fullHeight
                   :imageWidth="500"
                   overlayPadding="0px" image-format="jpeg"/>

        let file: File = this.base64ToFile(this.imageData!)
        base64ToFile(base64: string, filename: string) {
            let array = base64.split(','), mime = array[0].match(/:(.*?);/)![1], bstr = atob(array[1]), n = bstr.length, u8arr = new Uint8Array(n)
            while (n--) { u8arr[n] = bstr.charCodeAt(n) }
            return new File([u8arr], filename, {type:mime})
        }
     */

    static init(vue: Vue) {
        Vue.component(VImageInput.name, VImageInput)
        Vue.use(FileSelector);
    }

}
