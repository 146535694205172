import DrawerItem from "@/models/vue/DrawerItem";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import {AuthorityName} from "@/models/Authority";

let lang = getModule(LangModule).lang

type DrawerItemObject = {
    [key: string]: DrawerItem[]
}

const routesGroups: DrawerItemObject = {
    homeRoutes: [
        {title: lang.home, to: "/", icon: "mdi-home", subheader: lang.screenHome}
    ],

    catalogueRoutes: [  //Catalogo
        {title: lang.products, to: "/products", icon: "mdi-package-variant", subheader: lang.catalog, divider: true},	//Catalogo
        {title: lang.productCategories, to: "/product-categories", icon: "mdi-shape"},
        {title: lang.brands, to: "/brand", icon: "mdi-pound-box"},
    ],

    salesRoutes: [  //Ventas
        {title: lang.companies, to: "/companies", icon: "mdi-domain", subheader: lang.sales , divider: true},
        {title: "Historial de datos", to: "/history-data", icon: "mdi-home-analytics", allowed: [AuthorityName.SUPER_ADMIN, AuthorityName.ADMIN]},
    ],

    orderSalesRoutes: [ //Ventas (perspectiva del distribuidor y comercial)
        { subheader: lang.sales, divider: true, },
        {title: lang.myCompany, to: "/my-company", icon: "mdi-domain"},
        {title: lang.commercials, to: "/commercials", icon: "mdi-account-multiple", exclusions: [AuthorityName.COMMERCIAL]},
        {title: lang.myBills, to: "/invoices", icon: "mdi-receipt", exclusions: [AuthorityName.COMMERCIAL]},
        {title: lang.clients, to: "/clients", icon: "mdi-account-multiple", global: true},
        {title: lang.orders, to: "/my-orders", icon: "mdi-package-variant-closed"},
    ],

    marketingRoutes: [  //Marketing
        {title: lang.specialSales, to: "/promos", icon: "mdi-percent", subheader: "Marketing", divider: true},
        {title: lang.videos, to: "/videos", icon: "mdi-video"},
        {title: lang.catalog, to: "/catalogues", icon: "mdi-file-outline"},
        {title: lang.banners, to: "/banners", icon: "mdi-image"},
    ],

    settingsRoutes: [   //Configuraciones
        {title: lang.synchronization, to: "/synchronize", icon: "mdi-download-circle", subheader: lang.settings, divider: true},
        {title: "Logs", to: "/logs", icon: "mdi-home-analytics"},
        {title: lang.exclusions, to: "/product-exclusion", icon: "mdi-eye-off"},
        // {title: lang.restrictAreas, to: "/restricted-areas", icon: "mdi-card-bulleted-off"},
        {title: lang.notifications, to: "/notifications", icon: "mdi-bell"},
        {title: lang.users, to: "/users", icon: "mdi-account-multiple"},
        {title: "Configuración", to: "/config", icon: "mdi-cog", exclusions: [AuthorityName.ADMIN]},
    ],

    crmRoutes: [
        {title: "Clientes Potenciales", to: "/potential-clients", icon: "mdi-account-multiple", subheader: "C.R.M" , divider: true },	// CRM
        {title: "Presupuestos", to: "/my-budgets", icon: "mdi-package-variant"},
        {title: "Actividades", to: "/activities", icon: "mdi-pound-box"},
    ],

    headquarterRoutes: [
        {title: "Sedes", to: "/headquarters", icon:  "mdi-account-multiple", subheader: "Sedes", divider: true},
        {title: "Gestores de sedes", to: "/headquarter-managers", icon:  "mdi-account-multiple"},
        {title: "Pedidos", to: "/headquarter-orders", icon: "mdi-package-variant-closed"}
    ]

}

export default routesGroups