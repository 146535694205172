import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import Brand from "@/models/Brand";
import JsonTool from "@/services/tool/JsonTool";
import Client from "@/models/Client";
import Notification from "@/models/Notification";
import axios from "axios";
import Response from "@/models/responses/Response";
import response from "@/models/responses/Response";

export default class NotificationService {


    static async postNotification2(title: string, message: string, receivers: string[]): Promise<Response<Notification>> {

        const formData = new FormData()
        formData.set("subject", title)
        formData.set("message", message)
        formData.set("receivers", receivers.toString())

        try {
            const response = await axios.post(ConstantTool.BASE_URL + "/api/notification", formData, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const notification = JsonTool.jsonConvert.deserializeObject(response.data, Notification)
            return Promise.resolve({ result: notification })
        } catch (e) { return Promise.reject(e) }
    }

    static async postNotification(request: Notification): Promise<Response<Notification>> {
        try {
            const response = await axios.post(ConstantTool.BASE_URL + "/api/notification", request, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const notification = JsonTool.jsonConvert.deserializeObject(response.data, Notification)
            return Promise.resolve({ result: notification })
        } catch (err) { return Promise.reject(err) }
    }

    static async getMyNotifications(page: number, size: number): Promise<Response<Notification[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/notification/@me", {
                headers: {Authorization: getModule(SessionModule).session.token},
                params: { page, size }
            })
            const notifications = JsonTool.jsonConvert.deserializeArray(response.data, Notification)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: notifications, xTotalCount })
        } catch (e) { return Promise.reject(e) }
    }

    static async getNotifications(component: Vue, notifications: Notification[], page: number, size: number, search: string) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/notification", {
                params: { page, size, search },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Notification)
            notifications.splice(0, notifications.length)
            list.forEach(p => notifications.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"]);
            (<any>component).loading = false
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener las notificaciones");
            (<any>component).loading = false
        }
    }

    static async getNotification(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/notification/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.notification = JsonTool.jsonConvert.deserializeObject(response.data, Notification);
            // @ts-ignore
            component.loading = false;
        } catch (e) {
            // @ts-ignore
            component.loading = false;
            getModule(SnackbarModule).makeToast("No se pudo obtener la notificacion")
        }
    }

}
