



import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
// @ts-ignore
import { pleaseWait } from 'please-wait'
import 'please-wait/build/please-wait.css'

@Component
export default class SplashComponent extends Vue {

  @Prop() readonly isLoading!: boolean
  pleaseWaitInstance: pleaseWait | null = null

  mounted() {
    this.onLoadingChanged()
  }

  @Watch('isLoading')
  onLoadingChanged() {
    if (this.isLoading) {
      this.open()
    } else {
      this.close()
    }
  }

  open() {
    if (!this.pleaseWaitInstance) {
      this.pleaseWaitInstance = pleaseWait({
        logo: 'https://pre-apipanel.mobiledigitalsales.org/storage/ftp/logo_pb_dark.png',
        backgroundColor: '#E6E8E7',
        loadingHtml: '<h3 class="loading-text">we innovate in beauty</h3>'
      })
    }
  }
  
  close() {
    if (this.pleaseWaitInstance) {
      this.pleaseWaitInstance.finish()
      this.pleaseWaitInstance = null
    }
  }
}
