import ConstantTool from "@/services/tool/ConstantTool";
import LoginView from "@/views/LoginView.vue";
import SnackbarModule from "@/store/SnackbarModule";
import LoginResponse from "@/models/responses/LoginResponse"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import JsonTool from "@/services/tool/JsonTool"
import {Vue} from "vue-property-decorator"
import CompanyService from "@/services/CompanyService"
import CompanyModule from "@/store/CompanyModule"
import axios, {AxiosError} from "axios";
import NotificationsModule from "@/store/NotificationsModule";
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";

export default class LoginService {

	static postLogin(component: LoginView, email: string, password: string, snackbarModule: SnackbarModule, sessionModule: SessionModule) {
		// @ts-ignore
		component.progress = true

		let formData: FormData = new FormData()
		formData.set("username", email)
		formData.set("password", password)

		component.axios.post(ConstantTool.BASE_URL + "/public/oauth/panel-login", formData)
			.then(async response => {
				let loginResponse: LoginResponse = JsonTool.jsonConvert.deserializeObject(response.data, LoginResponse)
				let authority = loginResponse.authorities![0]
				sessionModule.session.token = "Bearer " + loginResponse.oauth2AccessToken!.accessToken
				sessionModule.session.user = loginResponse.user!
				sessionModule.session.authorities = loginResponse.authorities!

				await CompanyService.setMyCompanyOnModule()
				await getModule(NotificationsModule).startRefreshing()

				if (component.$route.query.redirect) {
					component.$router.push({path: component.$route.query.redirect as string}).catch(() => {
					})
				} else {
					component.$router.push({path: "/"}).catch(() => {})
				}

				await getModule(CategoryTreeviewModule).refreshProductCategories()
			})
			.catch(error => {
				let axiosError = error as AxiosError
				switch (axiosError.response?.status) {
					case(401): snackbarModule.makeToast("Acceso restringido."); break
					case(400): {
						if ((axiosError.response?.data?.message == "NOT_FOUND")) {snackbarModule.makeToast("Usuario no encontrado.")}
						if ((axiosError.response?.data?.message == "DISABLED_USER")) {snackbarModule.makeToast("Usuario desactivado..")}

						break;
					}
					default: snackbarModule.makeToast("Algo ha salido mal con la petición."); break;
				}
			})
			.finally(() => {
				// @ts-ignore
				component.progress = false
				// @ts-ignore
				component.loginButtonReset++
			})
	}

	static async logout(component: Vue) {
		try {
			await component.axios.post(ConstantTool.BASE_URL + "/api/oauth/logout", null, {
				headers: {Authorization: getModule(SessionModule).session.token}
			})
			let sessionModule: SessionModule = getModule(SessionModule)
			sessionModule.session.token = ""
			sessionModule.saveSession()
			getModule(CompanyModule).clear()
			getModule(NotificationsModule).clear()
			component.$router.push("/login").catch(()=>{})
			// getModule(BreadcrumbsModule).clear()
		} catch (e) { console.log("Logout error") }
	}

	static async checkSession() {
		try {
			const response = await axios.get(ConstantTool.BASE_URL + "/api/oauth/check", {
				headers: { Authorization: getModule(SessionModule).session.token }
			})
			return Promise.resolve()
		} catch (e) { return Promise.reject(e) }
	}

	static isLoged() {
		if (getModule(SessionModule).session.token == null) {
			return false
		} else {
			return !(getModule(SessionModule).session.token.trim() == "")
		}
	}

}
