

















import Vue from "vue"
import {Component} from "vue-property-decorator"
import DrawerComponent from "@/components/DrawerComponent.vue"
import NavbarComponent from "@/components/NavbarComponent.vue"
import SnackbarComponent from "@/components/SnackbarComponent.vue"
import FooterComponent from "@/components/FooterComponent.vue"
import DialogComponent from "@/components/DialogComponent.vue"
import {getModule} from "vuex-module-decorators"
import SessionModule from "@/store/SessionModule"
import SynchronizeComponent from "@/components/SynchronizeComponent.vue"
import BreadcrumbsComponent from "@/components/BreadcrumbsComponent.vue"
import LoadingComponent from "@/components/LoadingComponent.vue"
import CategoryTreeviewModule from "@/store/CategoryTreeviewModule";
import ConfigModule from "@/store/ConfigModule";
import LoginService from "@/services/LoginService";
import NotificationsModule from "@/store/NotificationsModule";
import CompanyService from "@/services/CompanyService";

@Component({components: {
    BreadcrumbsComponent, LoadingComponent, DialogComponent, DrawerComponent,
    FooterComponent, SnackbarComponent, SynchronizeComponent, NavbarComponent
}})
export default class App extends Vue {

	sessionModule: SessionModule = getModule(SessionModule)
	isLoading: boolean = true
  
  async created() {
    await this.configModules()
		this.$vuetify.theme.dark = getModule(SessionModule).session.dark
	}

  async configModules() {
    if (LoginService.isLoged()) {
      await getModule(CategoryTreeviewModule).refreshProductCategories()
      // await getModule(NotificationsModule).startRefreshing()
      await CompanyService.setMyCompanyOnModule()
    }
    await getModule(ConfigModule).refreshConfigs()
  }

  async mounted() {
		setTimeout(() => {
			this.isLoading = false
		}, 3000)
	}

	showMenu() {
    return (this.$route.name != null)
			 && ((this.$route.name != "login") && (this.$route.name != "recover-password"))
			 && (this.sessionModule.session.token)
  }

}
