import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject("Email")
export default class Email {

    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("mail", String, true)
    mail?: string = undefined

}
