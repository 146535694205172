





























































































import {Component, Vue} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import LoginService from "@/services/LoginService"
import SessionModule from "@/store/SessionModule"
import DrawerModule from "@/store/DrawerModule"
import LangModule from "@/store/LangModule"
import Language from "@/models/Language"
import NotificationsModule from "@/store/NotificationsModule"
import NotificationsMenu from "@/components/NotificationsMenu.vue"

@Component({ components: { NotificationsMenu } })
export default class NavbarComponent extends Vue {
  lang: any = getModule(LangModule).lang
  sessionModule: SessionModule = getModule(SessionModule)
  drawerModule: DrawerModule = getModule(DrawerModule)
  langModule: LangModule = getModule(LangModule)
  avatarInitials: string = ""
  notificationsModule = getModule(NotificationsModule)

  setLanguage(language: Language) {
    this.langModule.setLanguage(language)
    location.reload()
  }

  created() {
    this.createAvatarInitials()
    if (LoginService.isLoged()) {
      try {
        LoginService.checkSession()
      } catch (e) {
      }
    }
  }

  get mini() {
    return this.drawerModule.mini
  }

  set mini(enabled: boolean) {
    this.drawerModule.setMiniDrawerEnabled(enabled)
  }

  get dark() {
    return this.$vuetify.theme.dark
  }

  set dark(v: boolean) {
    this.sessionModule.session.dark = v
    this.sessionModule.saveSession()
    this.$vuetify.theme.dark = v
  }

  get commercialName() {
    return this.sessionModule.session.user.commercialName
  }

  logout() {
    LoginService.logout(this)
  }

  createAvatarInitials() {
    let name: string = this.sessionModule.session.user.name!;
    let lastname: string = this.sessionModule.session.user.lastname!
    let n: string = (name != null && name.length > 0) ? name.substring(0, 1).toUpperCase() : ""
    let l: string = (lastname != null && lastname.length > 0) ? lastname.substring(0, 1).toUpperCase() : ""
    if (n.length == 0 && l.length == 0) {
      this.avatarInitials = this.sessionModule.session.user.email!.substring(0, 1).toUpperCase()
    } else {
      this.avatarInitials = n + l;
    }
  }

}
