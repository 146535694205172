








import {Component, Vue, Watch} from "vue-property-decorator";
import {getModule} from "vuex-module-decorators";
import SynchronizeModule from "@/store/SynchronizeModule";
import SynchroService from "@/services/SynchroService";
import {SynchroType} from "@/models/Synchro";

@Component
export default class SynchronizeComponent extends Vue {

    // synchronizeModule: SynchronizeModule = getModule(SynchronizeModule);

    // get enabled() {
    //     return this.synchronizeModule.enabled
    // }
    //
    // set enabled(v: boolean) {
    //     this.synchronizeModule.setSnackbarEnabled(v)
    // }
    //
    // async synchronizeCompanies() {
    //     console.log("Sinchronize Companies")
    //     await SynchroService.synchronizeCompanies(this)
    // }
    //
    // async synchronizeProducts() {
    //     console.log("Sinchronize Products")
    //     await SynchroService.synchronizeProducts(this)
    // }
    //
    // @Watch('enabled')
    // async onEnabled() {
    //     console.log(this.synchronizeModule.synchroType)
    //     if (this.enabled) {
    //         if (this.synchronizeModule.synchroType == SynchroType.DISTRIBUTORS) {
    //             await this.synchronizeCompanies();
    //             this.enabled = false
    //         } else if (this.synchronizeModule.synchroType == SynchroType.PRODUCTS) {
    //             await this.synchronizeProducts();
    //             this.enabled = false
    //         }
    //     }
    // }

}
