




























import {Component, Vue} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import NotificationsModule from "@/store/NotificationsModule"
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

@Component({ components: { PerfectScrollbar } })
export default class NotificationsMenu extends Vue {

  lang: any = getModule(LangModule).lang
  langModule: LangModule = getModule(LangModule)
  notificationsModule = getModule(NotificationsModule)

  created() {

  }

  nextPage() {
    this.notificationsModule.nextPage()
  }
}
