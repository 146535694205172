import {Vue} from "vue-property-decorator";
import Product from "@/models/Product";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";
import Company from "@/models/Company";
import Authority, {AuthorityName} from "@/models/Authority";
import axios from "axios";
import CompanyModule from "@/store/CompanyModule";
import Response from "@/models/responses/Response";

export default class CompanyService {

    static async getCompanies(component: Vue, companies: Company[], page: number, size: number, search: string) {
        (<any>component).loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/companies", {
                params: { page, size, search },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, Company)
            companies.splice(0, companies.length)
            list.forEach(p => companies.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"]);
            // @ts-ignore
            component.loading = false
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener las empresas");
            (<any>component).loading = false
        }
    }

    static async getCompanies2(page: number, size: number, search: string): Promise<Response<Company[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/companies/", {
                params: { page, size, search },
                headers: { Authorization: getModule(SessionModule).session.token },
            })
            const companies = JsonTool.jsonConvert.deserializeArray(response.data, Company)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: companies, xTotalCount })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async getCompany(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/companies/" + id, {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.company = JsonTool.jsonConvert.deserializeObject(response.data, Company);
id
            // @ts-ignore
            component.loading = false;
        } catch (e) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se pudo obtener la empresa")
        }
    }

    static async getMyCompany(component: Vue) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/companies/me", {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            // @ts-ignore
            component.company = JsonTool.jsonConvert.deserializeObject(response.data, Company);
            // @ts-ignore
            component.loading = false;
        } catch (e) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("No se pudo obtener la empresa")
        }
    }

    static async activateCompanyCrm(component: Vue, company: Company) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/companies/" + company.id + "/activate-crm", null,{
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            if (response.status == 200) {
                getModule(SnackbarModule).makeToast("El estado de la empresa se ha cambiado con exito")
            }
            // @ts-ignore
            component.refresh()
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se han cambiar el estado de la empresa")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async deactivateCompanyCrm(component: Vue, company: Company) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/companies/" + company.id + "/deactivate-crm",null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            if (response.status == 200) {
                getModule(SnackbarModule).makeToast("El estado de la empresa se ha cambiado con exito")
            }
            // @ts-ignore
            component.refresh()
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se han cambiar el estado de la empresa")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async setMyCompanyOnModule() {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/companies/me", {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            let company = JsonTool.jsonConvert.deserializeObject(response.data, Company)
            getModule(CompanyModule).setCompany(company)
        } catch (e) {
            console.log(e)
        }
    }

}